import React from "react";
import {get} from "lodash";
import {graphql, useStaticQuery} from "gatsby";
import Helmet from "react-helmet";
import Section from "../components/section/section";
import styles from '../scss/page.scss';
import {Accordion as BootstrapAccordion, Card} from "react-bootstrap";
import Img from "gatsby-image/withIEPolyfill";
import { getStickyBarHeight, htmlFromText } from "../helpers.js"
import Layout from "../components/layout";
import SEO from "../components/seo";
import {authorUrl, buildBreadcrumbsItem, postUrl} from "../helpers";

class LandlordsRightsEsa extends React.Component {
  constructor(props) {
    super(props);
    this.author = {
      name: 'Prairie Conlon',
      title: 'LPC, NCC'
    }
    this.state = { posSticky: 0 };
    this.contentAccordion = [
      [
        {
          id: 'content-accordion-1',
          title: 'My tenant’s ESA is a “restricted breed animal” – can they be an ESA?',
          description: '<p>According to HUD, “breed, size, and weight limitations may not be applied to an assistance animal.”  Instead, a housing provider may only determine if <strong>the specific assistance animal in question</strong> poses a direct threat to the health and safety of others.</p><br><p> This determination of a “direct threat” must be based on “individualized assessment that relies on objective evidence about the specific animal’s actual conduct.” It may not be based on fears about a certain type of animal or evidence from damage done by previous animals of the same type.</p><br>'
        },
        {
          id: 'content-accordion-2',
          title: 'Can my tenant’s “restricted breed” emotional support dog increase my insurance?',
          description: '<p>The insurance company may label certain breeds of dogs as “dangerous” in the policy, but each reasonable accommodation determination must be made on a case-by-case basis.</p><br><p>Although, if a housing provider’s insurance carrier would cancel or substantially increase the costs of the insurance policy, HUD will find that this imposes an undue financial and administrative burden on the housing provider.</p><br><p>This claim must then be substantiated with the insurance company directly and comparable insurance coverage must be considered. if the insurance company has a policy that does not have an exception for an assistance animal, an investigation may be launched against the insurance company for potential disability discrimination.</p> <br><p>Which means, don’t stress. You’re on the right side of the law.</p><br>'
        },
        {
          id: 'content-accordion-3',
          title: 'What could happen if I violate the FHA?',
          description: '<p>The short answer, it’s not good.</p><br><p>When a person with a disability believes that he/she has been subjected to a discriminatory housing practice, including a provider’s wrongful denial of a request for reasonable accommodation, he/she may file a complaint with HUD within one year after the alleged denial or may file a lawsuit in federal district court within two years of the alleged denial. If a complaint is filed with HUD, HUD will investigate the complaint at no cost to the person with a disability.</p><br><p>If their case goes to an administrative hearing HUD attorneys will litigate the case on their behalf. An Administrative Law Judge (ALA) will consider evidence from them and the respondent. If the ALA decides that discrimination occurred, the respondent can be ordered:</p><br><ul><li>To compensate you for actual damages, including humiliation, pain and suffering.</li><li>To provide injunctive or other equitable relief.</li><li>To pay the Federal Government a civil penalty to vindicate the public interest. The maximum penalties are $16,000 for a first violation and $70,000 for a third violation within seven years.</li><li>To pay reasonable attorney’s fees and costs.</li><br>'
        },
        {
          id: 'content-accordion-4',
          title: 'What kind of housing does this apply to?',
          description: '<p>The laws apply to most buildings, but some exemptions include a building with 4 or fewer units, one of which is owner-occupied; single-family homes where the owner does not use a real estate agent to buy or rent the property, and the owner owns less than three single family houses; and housing owned by organizations or private clubs that is used for members.</p><br>'
        }
      ],
      [
        {
          id: 'content-accordion-1',
          title: 'Can a landlord verify the emotional support animal letter?',
          description: '<p>The letters include the following criteria:</p><br><ul><li>Written on the professional letterhead of the mental health professional along with their contact information, phone number, and email address.</li><li>The therapist’s license number.</li></ul><br><p>As a landlord, you can verify the letter in a number of ways without having direct contact with your tenant’s therapist. Attempting to do so may be considered a violation of federal law and the client could have cause to report you or your business to HUD. In order to avoid that, please use the following suggestions below to verify your client’s letter.</p>'
        },
        {
          id: 'content-accordion-2',
          title: 'How to verify an ESA letter without violating Federal Law:',
          description: '<ol><li><strong>Politely talk to your tenant about your concerns</strong> and let them know that you would like to work together to make sure that you’re comfortable with the letter. Remember, the tenant is disclosing to you that they are someone who is having a hard time. <i>Definitely avoid being short with them or accusing them of lying--this could be used against you in the event that a HUD case is filed</i>.</li><li><strong>Verifying the validity of the therapist’s license number</strong> which you can do by visiting the state website for their clinical discipline and entering their license number.</li><li>Below you will find a file with links to license verification for each state in the US. <a href="https://3d4igz27oxtl2iwox73y9smh-wpengine.netdna-ssl.com/wp-content/uploads/2018/02/License-Verification.pdf" target="_blank"><strong>License verification portal for each state.</strong></a></li><li>Asking the client to have their therapist complete a Reasonable Accommodation form. If you don’t already have one, <a href="https://3d4igz27oxtl2iwox73y9smh-wpengine.netdna-ssl.com/wp-content/uploads/2018/02/Alternative-Response-Form.pdf" target="_blank"><strong>you can download HERE</strong></a>.</li></ol>'
        },
        {
          id: 'content-accordion-3',
          title: 'As a landlord, you may not contact your tenant’s therapist',
          description: '<p>Remember, speaking with their therapist <strong>under any circumstances</strong> may be a violation of the Fair Housing Act. If you do however reach out to the therapist, the therapist would likely not be able to speak to you due to HIPAA privacy laws. If you’d like this form completed, you would need to ask your client to have their therapist complete it and the client would need to return the form do you directly.</p><br><p><strong>As a landlord, you may not ask your tenant:</strong></p><br><ul><li>“Do you have a disability?”</li><li>“How severe is your disability?”</li><li>“May I have permission to see your medical records?”</li><li>“Have you ever been hospitalized because of a mental disability?”</li><li>“Have you ever been in a drug rehabilitation program?”</li><li>“Do you take medications?”</li><li>“How long have you been in therapy?”</li><li>“How many sessions have you had with your therapist?”</li><li>Anything else at all about their symptoms or diagnosis besides what is provided to you on the letter.</li></ul><br><p>If you’d really like more information from your client’s therapist directly, <a href="https://3d4igz27oxtl2iwox73y9smh-wpengine.netdna-ssl.com/wp-content/uploads/2018/02/Alternative-Response-Form.pdf" target="_blank"><strong>you can download THIS DOCUMENT (Health/Social Service Professional Form)</strong></a>, which the Department of Housing and Urban Development (HUD) has been used in the past to verify a letter.</p><br>'
        },
        {
          id: 'content-accordion-4',
          title: 'Can a person have more than one service or emotional support animal?',
          description: '<p>The same thing that applies to one pet applies to multiple pets. If the tenant has documentation supporting more than one, they’re legitimate.</p><br>'
        },
        {
          id: 'content-accordion-5',
          title: 'Can an emotional support animals be an animal other than a cat or a dog?',
          description: '<p>Totally. HUD specifically states that “While dogs are the most common type of assistance animal, other animals can also be assistance animals.”</p><br>'
        },
        {
          id: 'content-accordion-6',
          title: 'Can a landlord deny a request for an Emotional Support Animal?',
          description: '<p>Typically, a landlord will have a difficult time establishing that an emotional support animal constitutes an undue burden. If the emotional assistance animal is particularly disruptive, or the tenant fails to take proper measures to ensure that the animal does not bother other tenants, the landlord may be justified in denying the accommodation or ultimately filing for an eviction.</p><br><p>But it’s important to remember that a landlord is not allowed to deny a reasonable accommodation based on the animal’s breed, weight or size since there is no restriction for an Emotional Support Animal.</p><br>'
        },
        {
          id: 'content-accordion-7',
          title: 'Can a landlord charge a pet deposit or pet rent?',
          description: '<p>A housing provider may not require an applicant or tenant to pay a fee or a security deposit in exchange for allowing the applicant or tenant to keep the emotional support animal.</p><br><p>If the emotional support animal causes damage to the housing unit or the common areas of the dwelling, that’s a different story, and in that instance, the housing provider may charge the cost of repairing the damage</p>'
        },
        {
          id: 'content-accordion-8',
          title: 'Why are there so many Emotional Support Animals these days?',
          description: '<p>1 out of 4 people suffer from either bipolar depression, PTSD, anxiety, personality disorder, or more. But with an ESA, they can face the everyday.</p><br><p>The human-animal bond is a powerful thing and we thank you for respecting and understanding that connection. It’s accommodating landlords like you that set an example for others nationwide and help encourage an alternative and very effective therapy for those who suffer from a mental or emotional disability.</p><br>'
        }
      ]
    ];
    this.itemsRefs = {}
    this.contentAccordion.map(( items, index ) => {
      this.itemsRefs[index] = {}
      items.map(( item, i ) => {
        this.itemsRefs[index][i] = React.createRef();
      })
    })
    this.breadcrumbs = [
      buildBreadcrumbsItem('Landlord\'s Rights', this.props.location.href)
    ]
    this.toggleHeaderClass = this.toggleHeaderClass.bind(this);
    this.seo = {
      title: 'Landlord’s Rights Regarding Emotional Support Animals',
      description:  'Everything you need to know as a Landlord about Emotional Support Animals, how to interact with tenants making an ESA request and how to validate an ESA letter.',
      seoTitle: 'Landlord’s Rights Regarding Emotional Support Animals'
    }
    this.post = {
      slug: 'landlords-rights-esa',
    }
  }
  componentDidMount() {
    this.setState(() => {
      return { posSticky: getStickyBarHeight };
    });
  }
  toggleHeaderClass(index, i) {
    if (this.itemsRefs) {
      for (let f in this.itemsRefs) {
        for (let x in this.itemsRefs[f]) {
          if ((index == f && x == i) || this.itemsRefs[f][x].current.classList.contains('open')) {
            this.itemsRefs[f][x].current.classList.toggle('open')
          }
        }
      }
    }
  }
  displayAccordion (n) {
    return (
      <BootstrapAccordion key={`accordion-${n}`} className="mt-3">
        {this.contentAccordion[n].map((item, i) => {
          return (
            <Card key={i}>
              <Card.Header ref={ this.itemsRefs[n][i] }>
                <BootstrapAccordion.Toggle as="h3" id={item.id} eventKey={i} onClick={this.toggleHeaderClass.bind(this, n, i)} className="col-12 col-md-6 mx-auto my-3">
                  <div className="row">
                    <div className="accordion-title col-10 col-md-10">{item.title}</div>
                    <div className="col-2 col-md-2">
                      <p className="icon float-md-right float-left"/>
                    </div>
                  </div>
                </BootstrapAccordion.Toggle>
              </Card.Header>
              <BootstrapAccordion.Collapse eventKey={i}>
                <Card.Body>
                  <div className="col-12 col-md-8 float-right mr-md-5" dangerouslySetInnerHTML={htmlFromText(item.description)}>
                  </div>
                </Card.Body>
              </BootstrapAccordion.Collapse>
            </Card>
          )

        })}
      </BootstrapAccordion>
    )
  }
  render() {
    const { data, children } = this.props
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`
    const baseUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.base')}`
    const defaultImage = get(this.props, 'data.defaultImage.childImageSharp.fluid')
    const publisherName = get(this.props, 'data.site.siteMetadata.publisher.name')
    const url = postUrl(this.post, get(this.props, 'data.site.siteMetadata.siteUrl'))
    return (
      <>
        <Layout containerClassNames="page landlord-rights" breadcrumbs={this.breadcrumbs} location={this.props.location}>
          <SEO { ...this.seo } />

          <article className="post-content-container" itemScope itemType="https://schema.org/BlogPosting">
            <meta itemProp="url" content={ url } />
            <link itemProp="mainEntityOfPage" href={ url } />
            <meta itemProp="datePublished" content="2020-12-12T17:51:29" />
            <meta itemProp="dateModified" content="2020-12-13T17:51:10" />
            <meta itemProp="headline" content="Landlord’s Rights Regarding Emotional Support Animals" />
            { publisherName ? (
              <link itemProp="publisher" href={ `#${publisherName.replace(' ', '')}Org` } />
            ) : ''}
            { this.author ? (
              <div itemProp="author" itemScope itemType="https://schema.org/Person">
                <meta itemProp="name" content={ this.author.name } />
                <meta itemProp="honorificSuffix" content={ this.author.title } />
              </div>
            ) : '' }
            <div className="post-content" itemProp="articleBody">

              <Section bgColor="#e0f0f0" className="background-img-content training px-0">
                <div className="header-section">
                  <div className="container">
                    <div className="row">
                      <div className="col-10 offset-1 title"><h1 id="landlord-rights" className="mt-4 mt-md-5 pt-md-3">Landlord Rights</h1></div>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="blog-data mt-5 pt-3 mx-auto text-center">
                    Reviewed by: <a href={authorUrl(this.author)} rel="author" className="blog-author">{this.author.name}, {this.author.title}</a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-md-6 mx-auto mt-5 mb-5">
                    <p>Welcome! If you’re a landlord, you’re in the right place. You have some concerns about a new tenant or a future tenant with an Emotional Support Animal Letter from a licensed mental health professional.</p>
                    <br/>
                    <p>Understood.</p>
                    <br/>
                    <p>Therapetic is committed in helping landlords understand their rights and assist you in verifying your tenant’s letter without violating the Fair Housing Act.</p>
                    <br/>
                    <p>The Fair Housing Act is a federal law that protects people from discrimination when they are renting, buying, or securing financing for any housing.
                    </p>
                  </div>
                </div>
              </Section>
              <Section bgColor="#fff" className="customize accordion pb-md-0 pt-md-5 py-0 px-0">
                <div className="row">
                  <div className="col-12 col-md-6 mx-auto mt-md-5 my-0">
                    <Img fluid={data.youngWomanWithHerEmotionalSupportAnimalInHerArms.childImageSharp.fluid} alt="Young woman with her emotional support animal in her arms" className="img-fluid lazyload" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-md-6 mx-auto mt-5 mb-5">
                    <h2>You’ve been shown an ESA letter--now what?</h2>
                    <br/>
                    <p>The first thing you should do is look into the validity of the letter--that’s within your rights. And one way to do so is by knowing the difference between a real ESA letter and a <br className="d-none d-md-block"/><strong><u><a
                      href={`${baseUrl}how-a-fake-emotional-support-animal-letter-ruined-my-vacation/`} target="_blank">fake one here</a></u></strong>.</p>

                    <div className="col-12 col-md-12 mt-4 text-center">
                      <a href={`${baseUrl}&utm_campaign=landlordspage&utm_content=cta1`} target="_blank" className="my-4 mx-auto btn-cta">LOOKING FOR AN ESA LETTER? GET STARTED HERE</a>
                    </div>
                  </div>
                </div>
                {this.displayAccordion(0)}
              </Section>
              <Section bgColor="#e5f5fa" className="customize bgaccordion pb-0 pt-md-5 py-0 px-0">
                <div className="row">
                  <div className="col-12 col-md-6 mx-auto mt-md-5 my-0">
                    <Img fluid={data.womanUsingHerLaptopWithHerEsaOnSide.childImageSharp.fluid} alt="Woman using her laptop with her esa on side" className="img-fluid lazyload" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-md-6 mx-auto mt-5 mb-5">
                    <h2>What kind of documentation can I require from the tenant?</h2>
                    <br/>
                    <p>The tenant must <strong><u><a href={`${baseUrl}get-started/`} target="_blank">provide documentation</a></u></strong> from a physician, psychiatrist, social worker, or other mental health professionals that the animal provides support that alleviates at least one of the identified symptoms or effects of the existing disability.</p>
                    <br/>
                    <p> This requires more than a short note that says this <strong><u><a href={`${baseUrl}register-your-cat-as-an-emotional-support-animal/`} target="_blank">emotional support cat</a></u></strong> or dog or any other type of animal makes a tenant feel good. Alleviating depression (if depression is a symptom of the mental condition, or the condition itself) is a function of an emotional support animal and should satisfy the requirement.</p>

                    <div className="col-12 col-md-12 mt-5 text-center">
                      <a href={`${ctaUrl}&utm_campaign=landlordspage&utm_content=cta2`} target="_blank" className="my-4 mx-auto btn-cta">LOOKING FOR AN ESA LETTER? GET STARTED HERE</a>
                    </div>
                  </div>
                </div>
                {this.displayAccordion(1)}
              </Section>

            </div>
            <meta itemProp="image" content={ defaultImage.src } />
          </article>
        </Layout>
      </>
    )
  }
}
export default (props) => {
  const data = useStaticQuery(graphql`
    query LandlordRights {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
            url {
              base
            }
          }
          siteUrl
          publisher {
            name
          }
        }
      } 
      youngWomanWithHerEmotionalSupportAnimalInHerArms: file(relativePath: { eq: "young-woman-with-her-emotional-support-animal-in-her-arms.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 778, maxHeight: 654) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      womanUsingHerLaptopWithHerEsaOnSide: file(relativePath: { eq: "woman-using-her-laptop-with-her-esa-on-side.jpg" }) {
        childImageSharp { 
          fluid(maxWidth: 778, maxHeight: 654) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      defaultImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <LandlordsRightsEsa  {...props} data={data}/>
  )
};
